<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "SPECIFICATIONS",
      items: [
        {
          text: "DASHBOARD",
          href:"/"
        },
        {
          text: "SPECIFICATIONS",
        }
      ],
      checkbox:[],
      isCheckAll:false,
      specificationData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "" },
        { key: "name", sortable: true, label: "SPECIFICATION NAME",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION",  thStyle: { color: "black", "font-size":"16px"}  }
      ],
      specifications: {
        name: "",
        status: "1"
      },
      editSpecification:{
        id: "",
        name: "",
        status: ""
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      successMessage: "",
      errorMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.specificationData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.specificationData.length;

    Master.specificationList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.specificationData = res.data.data;
    })
  },
  validations: {
    specifications: {
      name: { required, minLength: minLength(3) },
      status: { required }
    },
    editSpecification:{
      name: { required, minLength: minLength(3) },
      status: { required }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.specificationData) {
          this.checkbox.push(this.specificationData[key].id);
        }
      }
    },
    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.specificationStatusActive({
          ids:this.checkbox,
          status: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.specificationList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.specificationData = res.data.data;
          })
        })
      }

    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.specificationInactive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
          Master.specificationList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.specificationData = res.data.data;
          })
        })
      }
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.specificationBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            // this.successMessage = res.data.message;
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>"+res.data.message+"</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.specificationList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.specificationData = res.data.data;
            })
          })
        }
      }
    },

    editModal(data){
      this.editshowmodal = true
      Master.specificationDetails({
        id:data
      }).then((res) => {
        this.editSpecification.id = res.data.data.id
        this.editSpecification.name = res.data.data.name
        this.editSpecification.status = res.data.data.status
        console.log(res)
      })
    },

    async deleteSpecification(data){

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.specificationDelete(
              {
                trash: 0,
                id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            Master.specificationList({
              trash: 0
            }).then((res) => {
              console.log(res)
              this.specificationData = res.data.data;
            })
          })
        }
      });
    },
    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.specifications.$invalid) {
        return;
      } else {
        Master.specificationAdd(
            {
              name : this.specifications.name,
              status : this.specifications.status
            }).then((res) => {
          // console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.specificationList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.specificationData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          this.$bvToast.toast(""+err.response.data.error.name[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.specifications.status = 1;
      this.specifications.name = "";
    },
    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editSpecification.$invalid) {
        return;
      } else {
        Master.specificationUpdate(
            {
              id: this.editSpecification.id,
              name : this.editSpecification.name,
              status : this.editSpecification.status
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.specificationList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.specificationData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = true;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.specifications.name = "";
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-success mb-2 mr-1" @click="bulkActive">
                <i class="mdi mdi-check mr-2"></i> Active
              </a>
              <a href="javascript:void(0);" class="btn btn-outline-danger mb-2 mr-1" @click="bulkInactive">
                <i class="mdi mdi-cancel mr-2"></i> In-Active
              </a>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>
            <b-alert show dismissible variant="success" v-if="successMessage">
              <i class="mdi mdi-check-all mr-2"></i>{{successMessage}}
            </b-alert>
            <b-alert show dismissible variant="danger" v-if="errorMessage">
              <i class="mdi mdi-block-helper mr-2"></i>{{ errorMessage }}
            </b-alert>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="specificationData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>


                  <template v-slot:cell(name)="row">
                    <p class="badge font-size-12">
                      {{row.item.name}}
                    </p>
                  </template>




                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteSpecification(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->

    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD SPECIFICATION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">SPECIFICATION NAME</label>
          <input
              id="name"
              v-model="specifications.name"
              type="text"
              class="form-control"
              placeholder="ENTER SPECIFICATION NAME"
              :class="{ 'is-invalid': submitted && $v.specifications.name.$error }"
              style="text-transform: uppercase !important;"
          />
          <div v-if="submitted && $v.specifications.name.$error" class="invalid-feedback">
            <span v-if="!$v.specifications.name.required">SPECIFICATION NAME IS REQUIRED</span>
            <span v-if="!$v.specifications.name.minLength">SPECIFICATION NAME SHOULD BE ATLEAST 3 CHARACTER</span>
          </div>
        </div>


        <div class="form-group">
          <label for="position">STATUS</label>
          <select class="form-control"
                  id="position"
                  v-model="specifications.status"
                  :class="{ 'is-invalid': submitted && $v.specifications.status.$error }">
            <option value="1">ACTIVE</option>
            <option value="0">IN-ACTIVE</option>
          </select>
          <div
              v-if="submitted && !$v.specifications.status.required"
              class="invalid-feedback"
          >Status is required</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT SPECIFICATION"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="form-group">
          <label for="edit_name">SPECIFICATION NAME</label>
          <input
              id="edit_name"
              v-model="editSpecification.name"
              type="text"
              class="form-control"
              placeholder="ENTER SPECIFICATION NAME"
              :class="{ 'is-invalid': editSubmitted && $v.editSpecification.name.$error }"
              style="text-transform: uppercase !important;"
          />
          <div v-if="editSubmitted && $v.editSpecification.name.$error" class="invalid-feedback">
            <span v-if="!$v.editSpecification.name.required">SPECIFICATION NAME IS REQUIRED is required</span>
            <span v-if="!$v.editSpecification.name.minLength">SPECIFICATION NAME SHOULD BE ATLEAST 3 CHARACTER</span>
          </div>
        </div>


        <div class="form-group">
          <label for="edit_status">STATUS</label>
          <select class="form-control"
                  id="edit_status"
                  v-model="editSpecification.status"
                  :class="{ 'is-invalid': editSubmitted && $v.editSpecification.status.$error }">
            <option value="1">ACTIVE</option>
            <option value="0">IN-ACTIVE</option>
          </select>
          <div
              v-if="editSubmitted && !$v.editSpecification.status.required"
              class="invalid-feedback"
          >Status is required</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>